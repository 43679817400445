import { Box, Button, Card, CardBody, CardFooter, CardHeader, Center, Flex, HStack, Heading, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, VStack, useColorMode, useColorModeValue } from "@chakra-ui/react"
import Match from "./Match";
import { BsFillJournalBookmarkFill } from "react-icons/bs";
import { useEffect, useState } from "react";
import { getAuth, postAuth } from "../../../../Utils/APIHelpers";
import ReportScores from "../../Scores/ReportScores";
import { delay } from "framer-motion";


type MyMatchesProps = {
  endpoint: string;
  title: string;
  withReportScores: boolean;
}

export type MatchData = {
  matchId: number;
  team1Player1Name: string;
  team1Player2Name: string;
  team2Player1Name: string;
  team2Player2Name: string;
  winnerPlayerName: string;
  team1Player1Elo: number;
  team1Player1NewElo: number;
  team1Player2Elo: number;
  team1Player2NewElo: number;
  team2Player1Elo: number;
  team2Player1NewElo: number;
  team2Player2Elo: number;
  team2Player2NewElo: number;
  matchRecord: string;
  date: string;
}

const Matches: React.FC<MyMatchesProps> = ({endpoint, title, withReportScores}) => {

  const [matches, setMatches] = useState<MatchData[]>([])
  const [isOpen, setIsOpen] = useState(false);
  const [players, setPlayers] = useState<string[]>([]);
  const [score, setScore] = useState<number[]>([]);

  async function fetchMatches() {
    try {
        const response = await getAuth(endpoint);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const json = await response.json();
        setMatches(json as MatchData[]);
    } catch (error) {
        console.error('Error fetching matches:', error);
        setMatches([]); // Set empty array on error
    }
  }

  const handleOpenModal = () => {
    setIsOpen(true);
};

const handleCloseModal = () => {
    setIsOpen(false);
};

const handleReportScores = () => {
    // Handle submission of scores
    postAuth("/match/submit/", {
        'players': players,
        'scores': score
    })

    // Close the modal
    handleCloseModal();
    delay(fetchMatches, 1000)
};

  useEffect(() => {
    fetchMatches()
  }, [])
  console.log(matches)

    return (
        <Card variant={'elevated'} height={'100%'} overflow={'scroll'} minWidth={'lg'} width={'inherit'}>
          <CardHeader mt={'-2'}>
            <Center>
              <VStack>
                <HStack>
                  <BsFillJournalBookmarkFill size={"24"}/>
                  <Heading size={'lg'}>{title}</Heading>
                </HStack>
                {withReportScores &&
                  <Center>
                  <Button mt='2' variant={'outline'} colorScheme="green" onClick={handleOpenModal}>Report Scores</Button>
                  <Modal isOpen={isOpen} onClose={handleCloseModal} size={'2xl'}>
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader><Center>Report Scores</Center></ModalHeader>
                            <ModalCloseButton />
                            <ModalBody width={'inherit'}>
                                <ReportScores handleSetPlayers={(e) => setPlayers(e)} handleSetScores={(e) => setScore(e)}></ReportScores>
                            </ModalBody>
                            <Center>
                            <ModalFooter>
                                <Button colorScheme="blue" mr={3} onClick={handleReportScores}>Submit</Button>
                                <Button onClick={handleCloseModal}>Cancel</Button>
                            </ModalFooter>
                            </Center>
                        </ModalContent>
                    </Modal>
                  </Center>
                }
              </VStack>
            </Center>
          </CardHeader>
          <CardBody mt={'-7'}>
              <VStack>
                {matches.map((match) => (
                  <Flex key={`flex-${match.matchId}`}>
                    <Match key={match.matchId} myMatch={match} />
                  </Flex>
                ))}
              </VStack>
          </CardBody>
        </Card>
  )

}


export default Matches;