import React, { useState } from 'react';
import { Box, Heading, Text, VStack, Button, Link, useColorModeValue, HStack, Icon, keyframes, useToast, Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import { MdContentCopy } from 'react-icons/md';
import { BsYoutube } from 'react-icons/bs';

const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const StringingServices: React.FC = () => {
    const bgGradient = useColorModeValue(
        'linear-gradient(-45deg, blue.600, blue.500, blue.600)',
        'linear-gradient(-45deg, blue.700, blue.600, blue.700)'
    );

    const textColor = useColorModeValue('white', 'white');

    const toast = useToast();

    return (
        <Box 
            maxW="800px" 
            mx="auto" 
            p="8" 
            borderRadius="xl" 
            bgGradient={bgGradient}
            backgroundSize="200% 200%"
            color={textColor}
            boxShadow="2xl"
            my="8"
            sx={{
                '@media(prefers-reduced-motion: no-preference)': {
                    animation: `${gradientAnimation} 15s ease infinite`
                }
            }}
        >
            <VStack spacing="6" align="stretch">
                <Heading as="h1" size="xl" textAlign="center">
                    Professional Tennis Racquet Stringing Services
                </Heading>

                <Text fontSize="lg" fontWeight="bold" textAlign="center">
                    By Mark Sansait - MKETennis' Premier Stringer
                </Text>

                <Box>
                    <Heading as="h2" size="md" mb="3">
                        About Mark
                    </Heading>
                    <Text>
                        Mark Sansait brings his passion for tennis and technical expertise to every racquet he strings. 
                        As a former UW-Whitewater tennis player and current software engineer, Mark combines his 
                        attention to detail with his deep understanding of the game to provide exceptional stringing services.
                    </Text>
                </Box>

                <Box>
                    <Heading as="h2" size="md" mb="3">
                        Why Choose Mark?
                    </Heading>
                    <Text>
                        • Expert-level stringing with consistent, high-quality results<br />
                        • Competitive pricing in the Milwaukee area<br />
                        • Quick turnaround time<br />
                        • Personalized string recommendations based on your playing style<br />
                        • Deep understanding of how string tension affects play
                    </Text>
                </Box>

                <Box textAlign="center" pt="4">
                    <Heading as="h2" size="md" mb="4">
                        Contact for Stringing Services
                    </Heading>
                    <HStack justify="center" spacing={2}>
                        <Text
                            bg="blackAlpha.500"
                            px="4"
                            py="2"
                            borderRadius="md"
                            userSelect="all"
                            cursor="text"
                            _hover={{ bg: "blackAlpha.600" }}
                            boxShadow="0 0 10px rgba(255, 255, 255, 0.2)"
                            fontSize="lg"
                            fontWeight="medium"
                            letterSpacing="0.5px"
                        >
                            sansaitm23@gmail.com
                        </Text>
                        <Button
                            size="md"
                            onClick={() => {
                                navigator.clipboard.writeText("sansaitm23@gmail.com");
                                toast({
                                    title: "Email copied to clipboard",
                                    status: "success",
                                    duration: 2000,
                                    isClosable: true,
                                });
                            }}
                            colorScheme="whiteAlpha"
                            _hover={{ bg: 'whiteAlpha.300' }}
                        >
                            <Icon as={MdContentCopy} />
                        </Button>
                    </HStack>
                </Box>

                <Box textAlign="center" pt="4">
                    <Heading as="h2" size="md" mb="3">
                        Check Out Mark's Tennis Reviews
                    </Heading>
                    <Text mb="4">
                        Subscribe to Mark's YouTube channel for original tennis content including:<br />
                        • String reviews and comparisons<br />
                        • Racquet reviews and playtests<br />
                        • Tennis shoe reviews<br />
                        • Match-play
                    </Text>
                    <Button
                        leftIcon={<BsYoutube />}
                        colorScheme="red"
                        size="lg"
                        as={Link}
                        href="https://www.youtube.com/@MarkSansait"
                        isExternal
                        _hover={{ textDecoration: 'none', transform: 'scale(1.05)' }}
                        transition="all 0.2s"
                    >
                        Watch Tennis Reviews
                    </Button>
                </Box>
            </VStack>
        </Box>
    );
};

export default StringingServices; 