import { Box, Button, Card, CardBody, CardHeader, Center, Flex, Heading, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, Textarea, VStack, useDisclosure } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { getAuth, postAuth } from "../../Utils/APIHelpers";
import { ForumPost } from "../../types/Forum";
import { Input } from "@chakra-ui/react";

const Forum = () => {
    const [posts, setPosts] = useState<ForumPost[]>([]);
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [error, setError] = useState("");

    const fetchPosts = async () => {
        try {
            const response = await getAuth("/forum/posts/");
            if (!response.ok) {
                throw new Error("Failed to fetch posts");
            }
            const data = await response.json();
            setPosts(data.posts);
        } catch (error) {
            console.error("Error fetching posts:", error);
        }
    };

    const handleSubmit = async () => {
        try {
            if (!title.trim() || !content.trim()) {
                setError("Title and content are required");
                return;
            }

            const response = await postAuth("/forum/posts/", {
                title: title.trim(),
                content: content.trim()
            });

            if (!response.ok) {
                throw new Error("Failed to create post");
            }

            onClose();
            setTitle("");
            setContent("");
            setError("");
            fetchPosts();
        } catch (error) {
            console.error("Error creating post:", error);
            setError("Failed to create post");
        }
    };

    useEffect(() => {
        fetchPosts();
    }, []);

    return (
        <Box p={4}>
            <Flex justifyContent="space-between" alignItems="center" mb={4}>
                <Heading size="lg">Forum</Heading>
                <Button colorScheme="blue" onClick={onOpen}>Create Post</Button>
            </Flex>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Create New Post</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <VStack spacing={4}>
                            <Input
                                placeholder="Title"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                            <Textarea
                                placeholder="Content"
                                value={content}
                                onChange={(e) => setContent(e.target.value)}
                                size="lg"
                            />
                            {error && <Text color="red.500">{error}</Text>}
                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
                            Post
                        </Button>
                        <Button variant="ghost" onClick={onClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <VStack spacing={4} align="stretch">
                {posts.map((post) => (
                    <Card key={post.id}>
                        <CardHeader>
                            <Heading size="md">{post.title}</Heading>
                            <Text fontSize="sm" color="gray.500">
                                Posted by {post.authorName} on {new Date(post.createdAt).toLocaleDateString()}
                            </Text>
                        </CardHeader>
                        <CardBody>
                            <Text>{post.content}</Text>
                        </CardBody>
                    </Card>
                ))}
                {posts.length === 0 && (
                    <Center>
                        <Text>No posts yet. Be the first to post!</Text>
                    </Center>
                )}
            </VStack>
        </Box>
    );
};

export default Forum; 