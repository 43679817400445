import React, { Component, ReactNode } from 'react';
import {
  IconButton,
  Box,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Text,
  FlexProps,
  Spacer,
  Heading,
  Link as ChakraLink,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuDivider,
  Avatar,
  Button,
  StackDivider,
  Divider,
  Center,
  Portal,
  keyframes,
} from '@chakra-ui/react';
import { IconType } from 'react-icons';
import { BsFillDiagram3Fill, BsFillCalendarDateFill, BsPeopleFill, BsFillPinMapFill, BsFillPersonFill, BsFillChatFill, BsStarFill } from "react-icons/bs";
import { ReactText } from 'react';
import logo from '../Assets/placeholder-logo.png'
import {BellIcon, ChevronDownIcon} from '@chakra-ui/icons'
import { Link as RouterLink } from 'react-router-dom';

const pulseAnimation = keyframes`
  0% { box-shadow: 0 0 0 0 rgba(66, 153, 225, 0.4); }
  70% { box-shadow: 0 0 0 10px rgba(66, 153, 225, 0); }
  100% { box-shadow: 0 0 0 0 rgba(66, 153, 225, 0); }
`;

const sparkleAnimation = keyframes`
  0% { background-position: 0% 50%; }
  100% { background-position: 100% 50%; }
`;

const fireAnimation = keyframes`
  0% { box-shadow: 0 0 4px #ff6b6b, 0 0 8px #ff6b6b, 0 0 12px #ff8c00; }
  50% { box-shadow: 0 0 8px #ff4444, 0 0 16px #ff4444, 0 0 20px #ff8c00; }
  100% { box-shadow: 0 0 4px #ff6b6b, 0 0 8px #ff6b6b, 0 0 12px #ff8c00; }
`;

interface NavItemProps extends FlexProps {
    icon: IconType;
    children: ReactNode;
    link: string;
    isHighlighted?: boolean;
    isFireBorder?: boolean;
  }
  const HeaderItem = ({ icon, children, link, isHighlighted, isFireBorder, ...rest }: NavItemProps) => {
    const pulseAnimationStyle = isHighlighted ? `${pulseAnimation} 2s infinite` : 'none';
    const fireAnimationStyle = isFireBorder ? `${fireAnimation} 1.5s infinite` : 'none';
    const gradientBg = isHighlighted ? 
      'linear-gradient(90deg, #2B6CB0, #3182CE, #2B6CB0)' : 
      'transparent';
    
    return (
      <ChakraLink href={link} style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }}>
        <Flex
          align="center"
          p="4"
          mx="4"
          borderRadius="lg"
          role="group"
          cursor="pointer"
          bgGradient={isHighlighted ? gradientBg : 'none'}
          backgroundSize={isHighlighted ? '200% 100%' : 'auto'}
          color={isHighlighted ? 'white' : 'inherit'}
          transition="all 0.3s ease"
          animation={isFireBorder ? fireAnimationStyle : pulseAnimationStyle}
          _hover={{
            bg: isHighlighted ? 'blue.600' : isFireBorder ? 'orange.400' : 'cyan.400',
            color: 'white',
            transform: (isHighlighted || isFireBorder) ? 'scale(1.05)' : 'none',
          }}
          sx={{
            '@media(prefers-reduced-motion: no-preference)': {
              animation: isFireBorder ? fireAnimationStyle : 
                (isHighlighted ? `${sparkleAnimation} 3s linear infinite, ${pulseAnimation} 2s infinite` : 'none')
            }
          }}
          {...rest}>
          {icon && (
            <Icon
              mr="4"
              fontSize="16"
              color={isHighlighted || isFireBorder ? 'white' : 'inherit'}
              _groupHover={{
                color: 'white',
              }}
              as={icon}
            />
          )}
          {children}
        </Flex>
      </ChakraLink>
    );
  };
  
  interface MobileProps extends FlexProps {
    username: string | undefined;
    role: string | undefined;
  }


 const UserProfileComponent: React.FC<{name: string}> = ({name}) => {
  return (
    <HStack spacing={{ base: '0', md: '6' }}>
      <Flex alignItems={'center'}>
        <Menu>
          <MenuButton
            py={2}
            transition="all 0.3s"
            _focus={{ boxShadow: 'none' }}>
            <HStack>
              <Avatar size={'sm'}/>
              <VStack
                display={{ base: 'none', md: 'flex' }}
                alignItems="flex-start"
                spacing="1px"
                ml="2">
                <Text fontSize="sm">{name}</Text>
                <Text fontSize="xs" color="gray.600">
                  User
                </Text>
              </VStack>
              <Box display={{ base: 'none', md: 'flex' }}>
                <ChevronDownIcon />
              </Box>
            </HStack>
          </MenuButton>
          <Portal>
            <MenuList
              borderColor={useColorModeValue('gray.300', 'gray.550')}>
              <MenuItem as={RouterLink} to="/profile">
                Profile
              </MenuItem>
              <MenuItem>Settings</MenuItem>
              <MenuDivider/>
              <MenuItem onClick={() => {
                localStorage.removeItem("token")
                window.location.reload();
              }}>Sign out</MenuItem>
            </MenuList>
          </Portal>
        </Menu>
      </Flex>
    </HStack>
  )
 }


  const Header = ({ username, role, children }: MobileProps) => {

    return (
      <Box>
        <Flex
          px={{ base: 4, md: 4 }}
          py={'2'}
          height={'inherit'}
          alignItems="center"
          bg="gray.900"
          opacity='75%'
          borderBottomWidth="1px"
          borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
          justifyContent={{ base: 'space-between', md: 'flex-end' }}>
          <HStack divider={<StackDivider></StackDivider>}>
            <ChakraLink _hover={{ textDecoration: "none" }} href='/dashboard'>
              <Center pr={5}>
                  <Image src={logo} boxSize={'30px'} mr={3}></Image>
                  <Heading fontSize={'xx-large'} fontStyle={'italic'}>
                    MKE Tennis
                  </Heading>
              </Center>
            </ChakraLink>
            <HeaderItem icon={BsFillDiagram3Fill} link="/ladder" isFireBorder={true}>
              Ladder
            </HeaderItem>
            <HeaderItem 
              icon={BsFillDiagram3Fill} 
              link="/stringing-services" 
              isHighlighted={true}
            >
              <Flex align="center">
                Stringing Services
                <Icon as={BsStarFill} color="yellow.400" ml={2} fontSize="10px" />
                <Text fontSize="xs" color="yellow.400" ml={0.5}>(new)</Text>
              </Flex>
            </HeaderItem>
            <HeaderItem icon={BsFillCalendarDateFill} link="/events" isHighlighted={true}>
              <Flex align="center">
                Events
                <Icon as={BsStarFill} color="yellow.400" ml={2} fontSize="10px" />
                <Text fontSize="xs" color="yellow.400" ml={0.5}>(new)</Text>
              </Flex>
            </HeaderItem>
            <HeaderItem icon={BsPeopleFill} link="/pickuptennis">
              Pick Up Tennis
            </HeaderItem>
            <HeaderItem icon={BsFillPinMapFill} link="/courts">
              Courts
            </HeaderItem>
            <HeaderItem icon={BsFillChatFill} link="/forum" isHighlighted={true}>
              <Flex align="center">
                Forum
                <Icon as={BsStarFill} color="yellow.400" ml={2} fontSize="10px" />
                <Text fontSize="xs" color="yellow.400" ml={0.5}>(new)</Text>
              </Flex>
            </HeaderItem>
          </HStack>
          <Spacer/>
          <IconButton
            display={{ base: 'flex', md: 'none' }}
            variant="outline"
            aria-label="open menu"
            icon={<ChevronDownIcon />}
          />
          <Text
            display={{ base: 'flex', md: 'none' }}
            fontSize="2xl"
            fontFamily="monospace"
            fontWeight="bold">
            Logo
          </Text>
          {username ? <UserProfileComponent name={username} /> : <HeaderItem icon={BsFillPersonFill} link='/login'>Login</HeaderItem>}
        </Flex>
        {children ? (<Box ml={{ base: 0, md: 0 }} p="3">
          {children}
        </Box>) : <Flex/>}
      </Box>
    );
  };

  export default Header;