import { Avatar, Box, Card, Center, Flex, HStack, Heading, Spacer, Text, VStack, useColorModeValue } from "@chakra-ui/react";
import React, { ReactNode } from "react";

export type UpcomingMatchData = {
  finalMatchmakingResultId: number;
  matchmakingRequestSessionId: number;
  matchmakingRequestSessionName: string;
  matchmake_date: string;

  team1Player1Id: number;
  team1Player1FirstName: string;
  team1Player1LastName: string;
  team1Player1Elo: number;
  team1Player1Email: string;

  team1Player2Id: number | null;
  team1Player2FirstName: string | null;
  team1Player2LastName: string | null;
  team1Player2Elo: number | null;
  team1Player2Email: string | null;

  team2Player1Id: number;
  team2Player1FirstName: string;
  team2Player1LastName: string;
  team2Player1Elo: number;
  team2Player1Email: string;

  team2Player2Id: number | null;
  team2Player2FirstName: string | null;
  team2Player2LastName: string | null;
  team2Player2Elo: number | null;
  team2Player2Email: string | null;
}


type UpcomingMatchProps = {
  match: UpcomingMatchData;
};

function createAvatarAndName(playerFirstname: string, playerLastName: string, elo: number): ReactNode {
  return (
    <HStack>
      <Avatar size={'xs'} />
      <HStack>
        <Text fontWeight={'bold'}>{playerFirstname + " " + playerLastName}</Text>
        <Text fontSize={'small'} color={'gray.500'}>({elo})</Text>
      </HStack>
    </HStack>
  );
}

function createTeamVStack(player1FirstName: string, player1LastName: string, player1Elo: number, player2FirstName: string | null, player2LastName: string | null, player2Elo: number | null): ReactNode {
  if (!player2FirstName && !player2LastName) {
    return createAvatarAndName(player1FirstName, player1LastName, player1Elo);
  } else {
    return (
      <VStack align={'start'}>
        {createAvatarAndName(player1FirstName, player1LastName, player1Elo)}
        {createAvatarAndName(player2FirstName!, player2LastName!, player2Elo!)}
      </VStack>
    );
  }
}

const UpcomingMatch: React.FC<UpcomingMatchProps> = ({ match }) => {
  return (
    <Box p="2" borderRadius="md" overflow={'hidden'}>
      <Card minWidth='md' variant={'elevated'} p='4' bgColor={useColorModeValue('white', 'gray.600')} width={'inherit'}>
        <Center><Text fontWeight={'bold'}>{"Match session: " + match.matchmakingRequestSessionName}</Text></Center>
        <Center><Text fontWeight={'italics'}>{"Matchmaking date: " + match.matchmake_date}</Text></Center>
        <Flex direction={'column'} mt='3'>
          <Flex mb={'5'}>
            {createTeamVStack(
              match.team1Player1FirstName, match.team1Player1LastName, match.team1Player1Elo,
              match.team1Player2FirstName, match.team1Player2LastName, match.team1Player2Elo)}
            <Spacer />
            <Text fontWeight={'bold'}>vs</Text>
            <Spacer />
            {createTeamVStack(
              match.team2Player1FirstName, match.team2Player1LastName, match.team2Player1Elo,
              match.team2Player2FirstName, match.team2Player2LastName, match.team2Player2Elo)}
          </Flex>
        </Flex>
      </Card>
    </Box>
  );
};

export default UpcomingMatch;
