const DEFAULT_PATH = "/api"

const DEFAULT_HEADERS = {
  'Content-Type': 'application/json'
}

const GET_DEFAULT_HEADERS = {
  // Empty default headers for GET requests
}

export async function get(apiPath: string, body?: any, headers = GET_DEFAULT_HEADERS) {
  return await fetch(DEFAULT_PATH + apiPath, {
    method: 'GET',
    headers: headers
  })
}

export async function getAuth(apiPath: string, body?: any) {
  const headers = {
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  }
  return await get(apiPath, body, headers)
}

export async function post(apiPath: string, body?: any, headers = DEFAULT_HEADERS) {
  return await fetch(DEFAULT_PATH + apiPath, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(body)
  })
}

export async function postAuth(apiPath: string, body?: any) {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  }
  return await post(apiPath, body, headers)
}

export async function put(apiPath: string, body?: any, headers = DEFAULT_HEADERS) {
  return await fetch(DEFAULT_PATH + apiPath, {
    method: 'PUT',
    headers: headers,
    body: JSON.stringify(body)
  })
}

export async function putAuth(apiPath: string, body?: any) {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  }
  return await put(apiPath, body, headers)
}

export async function del(apiPath: string, body?: any, headers = DEFAULT_HEADERS) {
  return await fetch(DEFAULT_PATH + apiPath, {
    method: 'DELETE',
    headers: headers,
    body: JSON.stringify(body)
  })
}

export async function delAuth(apiPath: string, body?: any) {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  }
  return await del(apiPath, body, headers)
}
