import React from 'react';
import { 
    Box, 
    Heading, 
    Text, 
    VStack, 
    Image, 
    SimpleGrid, 
    Container,
    Divider,
    Link as ChakraLink,
    useColorModeValue,
    AspectRatio
} from "@chakra-ui/react";
import dronePicture from '../../Assets/drone picture.png';
import event1 from '../../Assets/event1.png';
import event2 from '../../Assets/event 2.png';
import event3 from '../../Assets/event 3.png';

const EventsPage: React.FC = () => {
    const bgColor = useColorModeValue('gray.50', 'gray.900');
    const cardBg = useColorModeValue('white', 'gray.800');
    const borderColor = useColorModeValue('gray.200', 'gray.700');

    return (
        <Box bg={bgColor} minH="100vh" py={8}>
            <Container maxW="1200px">
                {/* Hero Section with Drone Picture */}
                <Box 
                    mb={12} 
                    position="relative" 
                    borderRadius="xl" 
                    overflow="hidden"
                    boxShadow="2xl"
                    maxW="600px"
                    mx="auto"
                >
                    <AspectRatio ratio={4/3}>
                        <Image 
                            src={dronePicture} 
                            alt="Aerial view of tennis courts" 
                            objectFit="cover"
                            fallbackSrc={dronePicture}
                        />
                    </AspectRatio>
                    <Box 
                        position="absolute" 
                        bottom={0} 
                        left={0} 
                        right={0} 
                        bg="blackAlpha.700" 
                        p={6}
                    >
                        <Heading color="white" size="xl">Our First Event</Heading>
                        <Text color="whiteAlpha.900" fontSize="lg">
                            A Celebration of Tennis at McKinley Courts
                        </Text>
                    </Box>
                </Box>

                {/* Event Description */}
                <Box 
                    bg={cardBg} 
                    p={8} 
                    borderRadius="xl" 
                    boxShadow="lg" 
                    mb={12}
                    border="1px"
                    borderColor={borderColor}
                >
                    <VStack spacing={6} align="stretch">
                        <Text fontSize="xl" lineHeight="tall">
                            We kicked off our tennis community with an incredible event at the McKinley tennis courts! 
                            The day was filled with excitement as tennis enthusiasts from all over Milwaukee came together 
                            for fun drills, great music, and an engaging raffle. The atmosphere was electric as players 
                            of all skill levels participated in various activities and formed new connections within 
                            the tennis community.
                        </Text>

                        {/* Event Highlights Grid */}
                        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8} mt={8}>
                            <Box>
                                <Image 
                                    src={event1} 
                                    alt="Event highlight 1" 
                                    borderRadius="lg" 
                                    mb={4}
                                    boxShadow="md"
                                />
                                <Text fontWeight="bold" mb={2}>Professional Drills</Text>
                                <Text>Players participated in organized drills to improve their skills</Text>
                            </Box>
                            <Box>
                                <Image 
                                    src={event2} 
                                    alt="Event highlight 2" 
                                    borderRadius="lg" 
                                    mb={4}
                                    boxShadow="md"
                                />
                                <Text fontWeight="bold" mb={2}>Community Building</Text>
                                <Text>Tennis enthusiasts from all over Milwaukee came together</Text>
                            </Box>
                            <Box>
                                <Image 
                                    src={event3} 
                                    alt="Event highlight 3" 
                                    borderRadius="lg" 
                                    mb={4}
                                    boxShadow="md"
                                />
                                <Text fontWeight="bold" mb={2}>Exciting Atmosphere</Text>
                                <Text>Music and raffles added to the festive environment</Text>
                            </Box>
                        </SimpleGrid>
                    </VStack>
                </Box>

                {/* Upcoming Events Section */}
                <Box 
                    bg={cardBg} 
                    p={8} 
                    borderRadius="xl" 
                    boxShadow="lg"
                    border="1px"
                    borderColor={borderColor}
                >
                    <Heading as="h2" size="lg" mb={6}>Upcoming Events</Heading>
                    <ChakraLink 
                        href="https://mckinleymixed.com/" 
                        color="blue.500" 
                        fontSize="lg"
                        display="block"
                        mb={4}
                    >
                    
                    </ChakraLink>
                    <Divider my={6} />
                    <Text fontSize="xl" fontWeight="medium" color="blue.500">
                        Stay tuned for more exciting events!
                    </Text>
                    <Text fontSize="lg" mt={2}>
                        
                    </Text>
                </Box>
            </Container>
        </Box>
    );
};

export default EventsPage;
