import React, { useEffect, useState } from 'react';
import { Button, Flex, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Center, Box, Image, SimpleGrid } from "@chakra-ui/react";
import { getEmailFromToken, getNameFromToken } from '../../Utils/IsAuth';
import EloRankingComponent from './Components/EloRanking';
import Matches from './Components/Matches/Matches';
import Matchmaking from '../Matchmaking/Matchmaking';
import UpcomingMatches from '../Matchmaking/UpcomingMatches';
import { getAuth, postAuth } from '../../Utils/APIHelpers';
import ReportScores from './Scores/ReportScores';
import cjForehand from '../../Assets/cj_foreha.jpg';
import djoker from '../../Assets/djoker.png';
import drewFore from '../../Assets/drew_fore.jpg';
import kryscio from '../../Assets/kryscio.png';
import kryscio2 from '../../Assets/kryscio2.png';
import minigolf from '../../Assets/minigolf.jpg';

const ImageCollage = () => {
    const images = [cjForehand, djoker, drewFore, kryscio, kryscio2, minigolf];
    return (
        <Box position="relative" height="100%" width="300px" ml={4}>
            <SimpleGrid columns={2} spacing={2} height="100%">
                {images.map((img, index) => (
                    <Box 
                        key={index} 
                        position="relative" 
                        height="200px"
                        overflow="hidden"
                        borderRadius="md"
                    >
                        <Image
                            src={img}
                            alt={`Tennis image ${index + 1}`}
                            objectFit="cover"
                            width="100%"
                            height="100%"
                            filter="grayscale(100%)"
                            opacity="0.7"
                            transition="all 0.3s ease"
                            _hover={{
                                opacity: "0.9",
                                transform: "scale(1.05)"
                            }}
                        />
                    </Box>
                ))}
            </SimpleGrid>
        </Box>
    );
};

const DashboardPage: React.FC = () => {
    return (
        <Flex flexDirection={'column'}>
            <Flex flexDirection="row" height={'80vh'}>
                <Flex width={'md'}>
                    <EloRankingComponent isDetailed={false} />
                </Flex>
                <Flex ml={3}>
                    <Matches endpoint='/mymatches/' title='My Matches' withReportScores={true}></Matches>
                </Flex>
                <Flex ml={3}>
                    <Matchmaking endpoint='/matchmaking/' title='Matchmaking'></Matchmaking>
                </Flex>
                <ImageCollage />
            </Flex>
        </Flex>
    );
};

export default DashboardPage;
