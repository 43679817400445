import { jwtDecode } from 'jwt-decode';
import { decode } from 'punycode';

export const isAuth = (): boolean => {
    const token = localStorage.getItem('token');
    if (token) {
        try {
            const decoded: { exp: number } = jwtDecode(token);
            return decoded.exp > Date.now() / 1000;
        } catch (error) {
            console.error("Token decoding error:", error);
            return false;
        }
    }
    return false;
};

type TokenInfo = {
    email: string,
    name: string
}

export function getEmailFromToken(): string {
    const token = localStorage.getItem('token');
    if (token) {
        const decoded: { sub: TokenInfo; } = jwtDecode(token);
        return decoded.sub.email as string;
    }
    return "";
}

export const getNameFromToken = (): string => {
    const token = localStorage.getItem('token');
    if (token) {
        const decoded: { sub: TokenInfo } = jwtDecode(token);
        console.log(decoded.sub)
        return decoded.sub.name as string;
    }
    return ""
}