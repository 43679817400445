// App.tsx

import React, { useEffect, useState } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Info } from './Pages/Info';
import Login from './Pages/Login/Login';
import Register from './Pages/Signup/Register';
import DashboardPage from './Pages/Dashboard/Dashboart';
import { ProtectedRoute } from './Utils/ProtectedRoute';
import LadderPage from './Pages/Ladder/LadderPage';
import Profile from './Pages/Profile/Profile';
import StringingServices from './Pages/StringingServices/StringingServices';
import Header from './Pages/Header';
import EventsPage from './Pages/Events/Events';
import Courts from './Pages/Courts/Courts';
import Forum from './Pages/Forum/Forum';
import PickUpTennis from './Pages/PickUpTennis/PickUpTennis';
import { getNameFromToken } from './Utils/IsAuth';

export const App = () => {
  const [username, setUsername] = useState<string | undefined>(undefined);

  useEffect(() => {
    const name = getNameFromToken();
    setUsername(name || undefined);

    // Listen for storage events (when token changes)
    const handleStorageChange = () => {
      const name = getNameFromToken();
      setUsername(name || undefined);
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  return (
    <ChakraProvider>
      <Router>
        <Header username={username} role={undefined} />
        <Routes>
          <Route path="/" element={<Info />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/dashboard" element={<ProtectedRoute><DashboardPage /></ProtectedRoute>} />
          <Route path="/ladder" element={<ProtectedRoute><LadderPage /></ProtectedRoute>} />
          <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
          <Route path="/profile/:email" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
          <Route path="/stringing-services" element={<ProtectedRoute><StringingServices /></ProtectedRoute>} />
          <Route path="/events" element={<ProtectedRoute><EventsPage /></ProtectedRoute>} />
          <Route path="/pickuptennis" element={<ProtectedRoute><PickUpTennis /></ProtectedRoute>} />
          <Route path="/courts" element={<ProtectedRoute><Courts /></ProtectedRoute>} />
          <Route path="/forum" element={<ProtectedRoute><Forum /></ProtectedRoute>} />
        </Routes>
      </Router>
    </ChakraProvider>
  );
};

export default App;
