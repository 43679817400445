import { Box, Button, Card, CardBody, CardHeader, Center, Flex, HStack, Heading, IconButton, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Table, Tbody, Td, Text, Th, Thead, Tooltip, Tr, VStack, useToast, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter } from "@chakra-ui/react"
import { getAuth } from "../../../Utils/APIHelpers"
import { ReactNode, useEffect, useState } from "react"
import { BsFillTrophyFill, BsTrophy } from "react-icons/bs"
import { MdOutlineFileCopy, MdSportsScore } from "react-icons/md";
import { Link } from "react-router-dom";


const DEFAULT_PATH = "http://localhost:5000/api"


type EloRankingProps = {
    isDetailed: boolean
}

interface UserElo {
    firstName: string,
    lastName: string,
    elo: number,
    email: string
}

const EloRankingComponent: React.FC<EloRankingProps> = ({ isDetailed }: EloRankingProps) => {

    const [eloList, setEloList] = useState<UserElo[]>([])
    const [selectedUser, setSelectedUser] = useState<UserElo | null>(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();

    async function fetchElo() {
        try {
            const response = await getAuth("/users/elo")
            const data = await response.json();
            setEloList(data as UserElo[])
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        fetchElo()
    }, [])

    async function handleCopyClick(text: string) {
        try {
            await navigator.clipboard.writeText(text);
          } catch (error) {
            console.error('Error copying text:', error);
          }
    }

    const handleChallenge = (user: UserElo) => {
        setSelectedUser(user);
        onOpen();
    };

    const handleConfirmChallenge = () => {
        if (selectedUser) {
            // Get current user's info from JWT
            const token = localStorage.getItem('token');
            if (!token) return;
            
            const tokenData = JSON.parse(atob(token.split('.')[1]));
            const currentUserEmail = tokenData.sub || tokenData.email;
            const currentUserName = tokenData.name?.split(' ') || ['Unknown', 'User'];
            
            // Send challenge email
            fetch('/api/challenge/accept', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    challengerId: currentUserEmail,
                    challengeeId: selectedUser.email
                })
            });
            
            toast({
                title: "Challenge sent!",
                description: `An email has been sent to ${selectedUser.firstName}.`,
                status: "success",
                duration: 3000,
                isClosable: true,
            });
            onClose();
        }
    };

    function handleNameClick(user: UserElo): ReactNode {
        return (
            <Popover placement='top-start'>
                <PopoverTrigger>
                    <Text cursor={'pointer'} fontWeight={"bold"}>{user.firstName} {user.lastName}</Text>
                </PopoverTrigger>
                <PopoverContent>
                    <PopoverHeader fontWeight='semibold'>Player Info</PopoverHeader>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverBody>
                        <VStack align="stretch" spacing={2}>
                            <HStack>
                                <Text>Email: {user.email}</Text>
                                <Tooltip label="Copy">
                                    <IconButton 
                                        aria-label="Copy" 
                                        size={'xs'} 
                                        icon={<MdOutlineFileCopy/>} 
                                        onClick={(e) => handleCopyClick(user.email)}
                                    />
                                </Tooltip>
                            </HStack>
                            <Link to={`/profile/${user.email}`}>
                                <Button size="sm" width="100%" colorScheme="blue">
                                    View Profile
                                </Button>
                            </Link>
                            <Button
                                size="sm"
                                width="100%"
                                colorScheme="green"
                                leftIcon={<BsTrophy />}
                                onClick={() => handleChallenge(user)}
                            >
                                Challenge Player
                            </Button>
                            <Text fontSize="sm" color="gray.500">
                                Click 'Challenge Player' to send them an email challenge
                            </Text>
                        </VStack>
                    </PopoverBody>
                </PopoverContent>
            </Popover>
        )
    }

    return (
        <>
            <Card width={'inherit'} variant={'elevated'} overflow={'scroll'}>
                <Center pt='2'>
                    <VStack>
                    <HStack>
                        <BsFillTrophyFill/>
                        <Heading size={'md'} ml={3}>Ladder Rankings</Heading>
                    </HStack>
                    <Text fontStyle={"italic"}>
                        1) Click on a name to challenge or contact them
                    </Text>
                    <Text fontStyle={"italic"}>
                        2) After challenging, an email will be sent to the player saying you challenged them
                    </Text>
                    <Text fontStyle={"italic"}>
                        3) After playing, ONE person reports the score
                    </Text>
                    </VStack>
                </Center>
            <CardBody>
                <Card variant={'outline'}>
                <Table>
                    <Thead>
                        <Tr>
                            <Th>Rank</Th>
                            <Th>Name</Th>
                            <Th>Elo</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {eloList.map((user, index) => 
                            <Tr key={index}>
                                <Td>{index+1}</Td>
                                <Td>{handleNameClick(user)}</Td>
                                <Td>{user.elo}</Td>
                            </Tr>
                        )}
                    </Tbody>
                </Table>
                </Card>
            </CardBody>
        </Card>

        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Challenge Player</ModalHeader>
                <ModalBody>
                    Are you sure you want to challenge {selectedUser?.firstName} {selectedUser?.lastName}?
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="gray" mr={3} onClick={onClose}>
                        Cancel
                    </Button>
                    <Button colorScheme="green" onClick={handleConfirmChallenge}>
                        Yes, Challenge!
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
        </>
    );
}

export default EloRankingComponent;