// LoginPage.tsx

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { post } from '../../Utils/APIHelpers';
import { isAuth } from '../../Utils/IsAuth';
import LoginContainer from './LoginContainer';
import { Button, Checkbox, Flex, FormControl, Input, InputGroup, InputLeftElement, Link, Spacer, Text, VStack } from '@chakra-ui/react';
import { BsFileLockFill, BsPersonFill } from "react-icons/bs";
import { Link as ReactRouterLink } from 'react-router-dom';
import { Link as ChakraLink } from '@chakra-ui/react';

const LoginPage: React.FC = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const [formError, setFormError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        if (isAuth()) {
            navigate('/dashboard');
        } else {
            const savedEmail = localStorage.getItem('rememberMeEmail');
            if (savedEmail) {
                setEmail(savedEmail);
                setRememberMe(true);
            }
        }
    }, [navigate]);

    const handleLogin = async (e: React.FormEvent) => {
        e.preventDefault();
        setFormError('');

        if (!email) {
            setEmailError('Email is required.');
            return;
        }

        if (!password) {
            setPasswordError('Password is required.');
            return;
        }

        try {
            const response = await post("/login", { email, password })

            const data = await response.json();

            if (!response.ok) {
              throw new Error(data.message);
            }

            if (rememberMe) {
              localStorage.setItem('rememberMeEmail', email);
            } else {
              localStorage.removeItem('rememberMeEmail');
            }


            localStorage.setItem('token', data.token);
            // Trigger storage event for other components
            window.dispatchEvent(new StorageEvent('storage', {
                key: 'token',
                newValue: data.token
            }));
            navigate('/dashboard');
        } catch (err) {
            if (err instanceof Error) {
                setFormError(err.message);
            } else {
                setFormError('An unexpected error occurred.');
            }
        }
    };

    return (
        <LoginContainer>
            <form onSubmit={handleLogin}>
                <VStack spacing={4}>
                    <FormControl>
                        <InputGroup>
                            <InputLeftElement pointerEvents="none" children={<BsPersonFill/>} />
                            <Input 
                                placeholder='Email' 
                                type='email' 
                                value={email} 
                                onChange={e => setEmail(e.target.value)}
                                bg="gray.700"
                            />
                        </InputGroup>
                    </FormControl>
                    <FormControl>
                        <InputGroup>
                            <InputLeftElement pointerEvents="none" children={<BsFileLockFill />} />
                            <Input 
                                type="password" 
                                placeholder='Password' 
                                onChange={e => setPassword(e.target.value)}
                                bg="gray.700"
                            />
                        </InputGroup>
                    </FormControl>
                    <FormControl>
                        <Text color='red'>{formError || emailError || passwordError}</Text>
                    </FormControl>
                    <Flex width='100%'>
                        <Checkbox isChecked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)}>
                            Remember me 
                        </Checkbox>
                        <Spacer/>
                        <ChakraLink as={ReactRouterLink} to='/forgot-password' color="blue.300">
                            Forgot password?
                        </ChakraLink>
                    </Flex>
                    <Button width='100%' colorScheme="blue" type="submit">
                        Login
                    </Button>
                    <ChakraLink as={ReactRouterLink} to='/register' color="blue.300">
                        Don't have an account yet? Create one!
                    </ChakraLink>
                </VStack>
            </form>
        </LoginContainer>
    );
};

export default LoginPage;