import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { isAuth } from './IsAuth';

interface ProtectedRouteProps {
    children: ReactNode;
}

export function ProtectedRoute({children}: ProtectedRouteProps) {
    return isAuth() ? <>{children}</> : <Navigate to="/login" />;
}
