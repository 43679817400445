import { Box, Button, Flex, Heading, Text, VStack, Link, Center, SimpleGrid, Icon } from "@chakra-ui/react"
import tennisImage from '../Assets/tennis.jpg'
import { useNavigate } from "react-router";
import { BsFillDiagram3Fill, BsFillCalendarDateFill, BsPeopleFill, BsFillPinMapFill, BsFillChatFill } from "react-icons/bs";
import { GiTennisCourt } from "react-icons/gi";
import { FaTrophy } from "react-icons/fa";

export const Info: React.FC = () => {
    const navigate = useNavigate();

    const features = [
        { icon: BsFillDiagram3Fill, title: "Stringing Services", description: "Racquet stringing services by experienced players" },
        { icon: BsFillCalendarDateFill, title: "Automatic Matchmaking", description: "Get matched with players of similar skill level automatically" },
        { icon: BsPeopleFill, title: "Pick Up Tennis", description: "Find players for casual matches" },
        { icon: FaTrophy, title: "Challenge System", description: "Challenge players directly through email and track your results" },
        { icon: GiTennisCourt, title: "Ladder", description: "Competitive ladder with ELO rating system" },
        { icon: BsFillChatFill, title: "Forum", description: "Community discussions and announcements" }
    ];

    return (
        <Box 
            minH="100vh" 
            bgImage={tennisImage}
            backgroundPosition="center"
            backgroundRepeat="no-repeat"
            bgSize="cover"
            position="relative"
        >
            <Box
                position="absolute"
                top="0"
                left="0"
                right="0"
                bottom="0"
                bg="rgba(0, 0, 0, 0.7)"
                zIndex={0}
            />

            <Box position="relative" zIndex={1} pt={8}>
                <Box 
                    w="100%" 
                    py={20} 
                    textAlign="center"
                    borderBottom="1px"
                    borderColor="whiteAlpha.200"
                >
                    <Heading size="2xl" mb={6} color="white">Milwaukee's Free Tennis Ladder</Heading>
                    <Text fontSize="xl" mb={4} color="white">
                        with automated matchmaking. Register below to start playing!
                    </Text>
                    <Text fontSize="md" color="whiteAlpha.700">
                        See the{' '}
                        <Link
                            href="https://docs.google.com/document/d/1vz9SnJLdOPHNDyPwGcrb9o0cnJughMSag0KUiB0YGes/edit"
                            color="blue.300"
                            textDecoration="underline"
                            isExternal
                        >
                            about us
                        </Link>
                        {' '}for more info
                    </Text>
                </Box>

                <Flex direction={{ base: "column", md: "row" }} minH="calc(100vh - 200px)">
                    <Box 
                        flex="1" 
                        p={8} 
                        overflowY="auto"
                    >
                        <VStack spacing={8} align="stretch">
                            <Box bg="blackAlpha.700" p={6} borderRadius="lg">
                                <Heading size="lg" mb={6} color="white">Available Features</Heading>
                                <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
                                    {features.map((feature, index) => (
                                        <Box 
                                            key={index} 
                                            p={4} 
                                            bg="whiteAlpha.100" 
                                            borderRadius="md"
                                            _hover={{ bg: "whiteAlpha.200" }}
                                            transition="all 0.3s"
                                        >
                                            <Icon as={feature.icon} w={6} h={6} color="blue.300" mb={2} />
                                            <Heading size="sm" color="white" mb={2}>{feature.title}</Heading>
                                            <Text color="whiteAlpha.900" fontSize="sm">{feature.description}</Text>
                                        </Box>
                                    ))}
                                </SimpleGrid>
                            </Box>
                            <Box bg="blackAlpha.700" p={6} borderRadius="lg">
                                <Heading size="lg" mb={4} color="white">How It Works</Heading>
                                <Text color="white">
                                    1. Create your account<br/>
                                    2. Set your availability and preferences<br/>
                                    3. Get matched with players of similar skill<br/>
                                    4. Challenge players via email or use auto-matchmaking<br/>
                                    5. Play matches and climb the ladder
                                </Text>
                            </Box>
                        </VStack>
                    </Box>

                    <Box 
                        w={{ base: "100%", md: "400px" }}
                        p={8}
                        bg="blackAlpha.700"
                        borderRadius="lg"
                        m={4}
                    >
                        <VStack spacing={4}>
                            <Heading size="lg" color="white">Get Started</Heading>
                            <Button 
                                width='100%' 
                                colorScheme="blue" 
                                size="lg"
                                onClick={() => navigate('/register')}
                            >
                                Register Now
                            </Button>
                            <Text color="white">Already have an account?</Text>
                            <Button 
                                width='100%' 
                                colorScheme="blue"
                                size="lg"
                                onClick={() => navigate('/login')}
                            >
                                Login
                            </Button>
                        </VStack>
                    </Box>
                </Flex>
            </Box>
        </Box>
    );
}