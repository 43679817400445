import React, { useEffect, useState } from 'react';
import { Button, Flex, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Center, Box, Alert, AlertIcon, AlertTitle } from "@chakra-ui/react";
import { getEmailFromToken, getNameFromToken } from '../../Utils/IsAuth';
import EloRankingComponent from './Components/EloRanking'; // Make sure to import the EloRankingComponent
import Matches from './Components/Matches/Matches';
import Matchmaking from '../Matchmaking/Matchmaking';
import UpcomingMatches from '../Matchmaking/UpcomingMatches';
import { getAuth, postAuth } from '../../Utils/APIHelpers';
import ReportScores from './Scores/ReportScores';

const DashboardPage: React.FC = () => {

    return (
        <Flex flexDirection={'column'}>
            <Box mb={1} mx={1} mt={-2}>
                <Alert status="success">
                    <AlertIcon />
                    <AlertTitle mr={2}>Congrats CJ Kryscio on winning state!</AlertTitle>
                </Alert>
            </Box>
            <Flex flexDirection="row" height={'80vh'}>
                <Flex width={'md'}>
                    <EloRankingComponent isDetailed={false} />
                </Flex>
                <Flex ml={3}>
                    <Matches endpoint='/mymatches/' title='My Matches' withReportScores={true}></Matches>
                </Flex>
                <Flex ml={3}>
                    <Matchmaking endpoint='/matchmaking/' title='Matchmaking'></Matchmaking>
                </Flex>
            </Flex>
        </Flex>
    );
};

export default DashboardPage;
