import { Badge, Box, Button, Card, CardBody, CardFooter, CardHeader, Center, Flex, HStack, Heading, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, VStack, Text } from "@chakra-ui/react";
import { BsFillJournalBookmarkFill } from "react-icons/bs";
import { MdUpcoming } from "react-icons/md";
import { useEffect, useState } from "react";
import { IoPeopleSharp } from "react-icons/io5";
import { getAuth, postAuth } from "../../Utils/APIHelpers";
import { Select } from "chakra-react-select";
import UpcomingMatches from './UpcomingMatches';

type MatchmakingProps = {
  endpoint: string;
  title: string;
};

export type MatchmakingRequestSessionData = {
  matchmakingRequestSessionId: string;
  matchmakingRequestSessionName: string;
  matchmakingRequestSessionCycle: string;
  matchmakingRequestSessionStartDate: string;
  matchmakingRequestSessionEndDate: string;
};

export type requestResponse = {
  message: string;
  isError: boolean;
};

const Matchmaking: React.FC<MatchmakingProps> = ({ title, endpoint }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [matchmakingRequestSessions, setMatchmakingRequestSessions] = useState<MatchmakingRequestSessionData[]>([]);
  const [requestResponse, setRequestResponse] = useState<requestResponse>({ message: "", isError: false });
  const [selectedSession, setSelectedSession] = useState<MatchmakingRequestSessionData | null>(null);
  const [requestStatus, setRequestStatus] = useState<{ [key: string]: boolean }>({});

  // Fetch active matchmaking reqeust sessions
  async function fetchMatchmakingRequestSessions() {
    try {
      const response = await getAuth('/matchmaking/matchmakingrequestsessions/');
      const data = await response.json();
      setMatchmakingRequestSessions(Array.isArray(data) ? data : []);
    } catch (error) {
      console.error("Error fetching matchmaking request sessions:", error);
      setMatchmakingRequestSessions([]);
    }
  }

  const handleOpenModal = () => {
    setIsOpen(true);
    fetchMatchmakingRequestSessions();
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    setSelectedSession(null);
    setRequestResponse({ message: "", isError: false });
  };



  const handleRequestMatchmaking = async () => {
    if (!selectedSession) {
      alert("Please select a matchmaking session.");
      return;
    }

    try {
      const response = await postAuth('/matchmaking/submitrequest/', {
        'matchmakingRequestSessionId': selectedSession.matchmakingRequestSessionId,
        'matchmakingRequestSessionName': selectedSession.matchmakingRequestSessionName,
        'matchmakingRequestSessionStartDate': selectedSession.matchmakingRequestSessionStartDate,
        'matchmakingRequestSessionEndDate': selectedSession.matchmakingRequestSessionEndDate
      });

      if (response.status === 200) {
        setRequestResponse({ message: "Request submitted successfully.", isError: false });
        handleCloseModal();
      } else if (response.status === 400) {
        setRequestResponse({ message: "A request is already recorded for this matchmaking cycle.", isError: true });
      }
    } catch (error) {
      console.error("Error submitting matchmaking request:", error);
    }
  };

  const checkForRequest = async (session: MatchmakingRequestSessionData) => {

    try {
      const response = await postAuth('/matchmaking/checkformatchmakingrequest/', {
        'matchmakingRequestSessionId': session.matchmakingRequestSessionId
      });

      if (response.status === 200) {
        // requested submitted
        return true;
      } else if (response.status === 400) {
        // request not submitted
        return false;
      }
    } catch (error) {
      console.error("Could not obtain request status for session.", error);
      return false;
    }
  };

  useEffect(() => {
    matchmakingRequestSessions.forEach(async (session) => {
      const hasRequested = await checkForRequest(session);
      setRequestStatus((prevMap) => ({
        ...prevMap,
        [session.matchmakingRequestSessionId]: hasRequested ?? false,
      }));
    });
  }, [matchmakingRequestSessions]);


  return (
    <Card variant={'elevated'} height={'100%'} overflow={'scroll'} minWidth={'lg'} width={'inherit'}>
      <CardHeader mt={'-2'}>
        <Center>
          <VStack>
            <HStack>
              <IoPeopleSharp size={"24"} />
              <Heading size={'lg'}>{title}</Heading>
            </HStack>

            <Center>
              <Button mt='2' variant={'outline'} colorScheme="blue" onClick={handleOpenModal}>
                Request Matchmaking
              </Button>

              {/* Modal for matchmaking session request */}
              <Modal isOpen={isOpen} onClose={handleCloseModal} size={'2xl'}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader><Center>Request Matchmaking</Center></ModalHeader>
                  <ModalCloseButton />
                  <ModalBody width={'inherit'}>
                    <VStack spacing={4}>
                      {matchmakingRequestSessions.length === 0 && (
                        <VStack>
                          <Text> No active matchmaking sessions.</Text>
                          <Text> Try again later.</Text>
                        </VStack>
                      )}


                      {/* Render each matchmaking session as a card */}
                      {matchmakingRequestSessions.map((session) => (
                        <Card
                          key={session.matchmakingRequestSessionId}
                          variant="outline"
                          width="100%"
                          maxWidth="600px"
                          height="auto"
                          border={selectedSession?.matchmakingRequestSessionId === session.matchmakingRequestSessionId ? '2px solid green' : '1px solid gray'}
                          onClick={() => setSelectedSession(session)}
                          cursor="pointer"
                        >
                          <CardBody>
                            <Center><Heading size="md">{session.matchmakingRequestSessionName}</Heading></Center>
                            <Text><b>Start Date:</b> {session.matchmakingRequestSessionStartDate}</Text>
                            <Text><b>End Date:</b> {session.matchmakingRequestSessionEndDate}</Text>
                            <Text><b>Matchmaking Cycle:</b> {session.matchmakingRequestSessionCycle} day(s)</Text>
                            {/* Notification if player has already requested matchmaking */}
                            {requestStatus[session.matchmakingRequestSessionId] ? (
                              <Badge colorScheme="green" mt={2}>Request Submitted</Badge>
                            ) : (
                              <Badge colorScheme="red" mt={2}>No Request submitted</Badge>
                            )}
                          </CardBody>
                        </Card>
                      ))}

                      {requestResponse.isError && (
                        <Text color="Red">{requestResponse.message}</Text>
                      )}
                    </VStack>
                  </ModalBody>
                      {matchmakingRequestSessions.length !== 0 ? (
                        <Center>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={handleRequestMatchmaking}>
                        Submit
                      </Button>
                      <Button onClick={handleCloseModal}>Cancel</Button>
                      </ModalFooter>
                  </Center>
                    ):null}
                </ModalContent>
              </Modal>
            </Center>
            <Box width="100%" mt={10}>
              <UpcomingMatches endpoint='/matchmaking/finalmatchmakingsessions/' title='My Upcoming Matches' isLadderTab={false} />
            </Box>
          </VStack>
        </Center>
      </CardHeader>
    </Card>
  );
};

export default Matchmaking;
