import { Box, Button, Card, CardBody, Divider, Flex, FormControl, FormLabel, Heading, Input, Stack, Text, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getAuth, putAuth } from "../../Utils/APIHelpers";
import { UserProfile } from "../../types/Profile";
import { getEmailFromToken } from "../../Utils/IsAuth";

const Profile = () => {
    const { email } = useParams();
    const [profile, setProfile] = useState<UserProfile | null>(null);
    const [isEditing, setIsEditing] = useState(false);
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        highestUstaRanking: "",
        preferredPlayTime: "",
        occupation: "",
        company: "",
        calendarLink: "",
        playPreference: ""
    });
    const toast = useToast();
    const currentUserEmail = getEmailFromToken();
    const isOwnProfile = !email || email === currentUserEmail;

    const fetchProfile = async () => {
        try {
            const endpoint = email ? `/profile/${email}` : '/profile';
            const response = await getAuth(endpoint);
            if (!response.ok) {
                throw new Error("Failed to fetch profile");
            }
            const data = await response.json();
            setProfile(data);
            setFormData({
                firstName: data.firstName || "",
                lastName: data.lastName || "",
                highestUstaRanking: data.highestUstaRanking || "",
                preferredPlayTime: data.preferredPlayTime || "",
                occupation: data.occupation || "",
                company: data.company || "",
                calendarLink: data.calendarLink || "",
                playPreference: data.playPreference || ""
            });
        } catch (error) {
            console.error("Error fetching profile:", error);
            toast({
                title: "Error",
                description: "Failed to load profile",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const handleSubmit = async () => {
        try {
            // Clean up the form data before sending
            const cleanFormData = {
                firstName: formData.firstName.trim(),
                lastName: formData.lastName.trim(),
                highestUstaRanking: formData.highestUstaRanking.trim(),
                preferredPlayTime: formData.preferredPlayTime.trim(),
                occupation: formData.occupation.trim(),
                company: formData.company.trim(),
                calendarLink: formData.calendarLink.trim(),
                playPreference: formData.playPreference.trim()
            };

            // Use the same endpoint format as GET request
            const endpoint = email ? `/profile/${email}` : '/profile';
            const response = await putAuth(endpoint, cleanFormData);
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || "Failed to update profile");
            }
            setIsEditing(false);
            fetchProfile();
            toast({
                title: "Success",
                description: "Profile updated successfully",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        } catch (error) {
            console.error("Error updating profile:", error);
            toast({
                title: "Error",
                description: error instanceof Error ? error.message : "Failed to update profile",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    useEffect(() => {
        fetchProfile();
    }, [email]);

    if (!profile) {
        return (
            <Box p={4}>
                <Text>Loading profile...</Text>
            </Box>
        );
    }

    return (
        <Box p={4}>
            <Card>
                <CardBody>
                    <Stack spacing={6}>
                        <Flex justifyContent="space-between" alignItems="center">
                            <Heading size="lg">
                                {profile.firstName} {profile.lastName}'s Profile
                            </Heading>
                            {isOwnProfile && (
                                <Button
                                    colorScheme="blue"
                                    onClick={() => isEditing ? handleSubmit() : setIsEditing(true)}
                                >
                                    {isEditing ? "Save Changes" : "Edit Profile"}
                                </Button>
                            )}
                        </Flex>

                        {/* Basic Information - Editable */}
                        <Box>
                            <Heading size="md" mb={4}>Basic Information</Heading>
                            {isEditing ? (
                                <Stack spacing={4}>
                                    <FormControl>
                                        <FormLabel>First Name</FormLabel>
                                        <Input
                                            value={formData.firstName}
                                            onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Last Name</FormLabel>
                                        <Input
                                            value={formData.lastName}
                                            onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Highest USTA Ranking</FormLabel>
                                        <Input
                                            value={formData.highestUstaRanking}
                                            onChange={(e) => setFormData({ ...formData, highestUstaRanking: e.target.value })}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Preferred Play Time</FormLabel>
                                        <Input
                                            value={formData.preferredPlayTime}
                                            onChange={(e) => setFormData({ ...formData, preferredPlayTime: e.target.value })}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Play Preference</FormLabel>
                                        <Input
                                            value={formData.playPreference}
                                            onChange={(e) => setFormData({ ...formData, playPreference: e.target.value })}
                                        />
                                    </FormControl>
                                </Stack>
                            ) : (
                                <Stack spacing={3}>
                                    <Box>
                                        <Text fontWeight="bold">Email</Text>
                                        <Text>{profile.email}</Text>
                                    </Box>
                                    <Box>
                                        <Text fontWeight="bold">Highest USTA Ranking</Text>
                                        <Text>{profile.highestUstaRanking || "Not provided"}</Text>
                                    </Box>
                                    <Box>
                                        <Text fontWeight="bold">Preferred Play Time</Text>
                                        <Text>{profile.preferredPlayTime || "Not provided"}</Text>
                                    </Box>
                                    <Box>
                                        <Text fontWeight="bold">Play Preference</Text>
                                        <Text>{profile.playPreference || "Not provided"}</Text>
                                    </Box>
                                </Stack>
                            )}
                        </Box>

                        <Divider />

                        {/* Professional Information - Editable */}
                        <Box>
                            <Heading size="md" mb={4}>Professional Information</Heading>
                            {isEditing ? (
                                <Stack spacing={4}>
                                    <FormControl>
                                        <FormLabel>Occupation</FormLabel>
                                        <Input
                                            value={formData.occupation}
                                            onChange={(e) => setFormData({ ...formData, occupation: e.target.value })}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Company</FormLabel>
                                        <Input
                                            value={formData.company}
                                            onChange={(e) => setFormData({ ...formData, company: e.target.value })}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Calendar Link</FormLabel>
                                        <Input
                                            value={formData.calendarLink}
                                            onChange={(e) => setFormData({ ...formData, calendarLink: e.target.value })}
                                        />
                                    </FormControl>
                                </Stack>
                            ) : (
                                <Stack spacing={3}>
                                    <Box>
                                        <Text fontWeight="bold">Occupation</Text>
                                        <Text>{profile.occupation || "Not provided"}</Text>
                                    </Box>
                                    <Box>
                                        <Text fontWeight="bold">Company</Text>
                                        <Text>{profile.company || "Not provided"}</Text>
                                    </Box>
                                    <Box>
                                        <Text fontWeight="bold">Calendar Link</Text>
                                        <Text>{profile.calendarLink || "Not provided"}</Text>
                                    </Box>
                                </Stack>
                            )}
                        </Box>

                        <Divider />

                        {/* Tennis Statistics - Read Only */}
                        <Box>
                            <Heading size="md" mb={4}>Tennis Statistics</Heading>
                            <Stack spacing={3}>
                                <Box>
                                    <Text fontWeight="bold">Rating</Text>
                                    <Text>{profile.rating || "Not rated"}</Text>
                                </Box>
                                <Box>
                                    <Text fontWeight="bold">Record</Text>
                                    <Text>{profile.wins || 0}W - {profile.losses || 0}L</Text>
                                </Box>
                            </Stack>
                        </Box>
                    </Stack>
                </CardBody>
            </Card>
        </Box>
    );
};

export default Profile; 