import React, { useEffect, useState } from 'react';
import { Button, Flex, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Center, Box, Alert, AlertIcon, AlertTitle, Heading, Text, Tooltip, Icon } from "@chakra-ui/react";
import { getEmailFromToken, getNameFromToken } from '../../Utils/IsAuth';
import Matches from '../Dashboard/Components/Matches/Matches';
import EloRankingComponent from '../Dashboard/Components/EloRanking';
import UpcomingMatches from '../Matchmaking/UpcomingMatches';
import { FaTrophy } from 'react-icons/fa';

const LadderPage: React.FC = () => {
    const email: string = getEmailFromToken();

    return (
        <Flex flexDirection={'column'}>
            <Box mx={7} mt={4} mb={2}>
                <Alert 
                    status="info" 
                    variant="subtle" 
                    borderRadius="md"
                    bg="blue.700"
                    color="white"
                >
                    <AlertIcon color="white" />
                    <Box>
                        <AlertTitle fontSize="lg">
                            New Feature: Try our{' '}
                            <Tooltip 
                                label="Click on any player's name in the ladder to challenge them to a match! They'll receive a notification and can accept or decline." 
                                placement="bottom"
                                hasArrow
                            >
                                <Text as="span" fontWeight="bold" textDecoration="underline" cursor="help">
                                    Challenge System <Icon as={FaTrophy} display="inline" mb={1} />
                                </Text>
                            </Tooltip>
                        </AlertTitle>
                    </Box>
                </Alert>
            </Box>
            <Flex flexDirection="row" height={'86vh'} ml={'7'}>
                <Flex width={'md'}>
                    <EloRankingComponent isDetailed={true} />
                </Flex>
                <Flex ml={3} width={'xl'}>
                    <Matches endpoint='/matches/' title='All Matches' withReportScores={false}></Matches>
                </Flex>
                <Flex ml={3} width={'xl'}>
                    <UpcomingMatches endpoint='/matchmaking/finalmatchmakingsessions/' title='All Upcoming Matches' isLadderTab = {true}></UpcomingMatches>
                </Flex>
            </Flex>
        </Flex>
    );
};

// export default DashboardPage;

export default LadderPage;