import React, { useEffect, useState } from "react";
import { VStack, Card, CardHeader, Center, Box, HStack, Heading, Text } from "@chakra-ui/react";
import UpcomingMatch, { UpcomingMatchData } from "./UpcomingMatch";
import { MdUpcoming } from "react-icons/md";
import { getAuth } from "../../Utils/APIHelpers"; // Assuming this is used to fetch data
import { getEmailFromToken } from "../../Utils/IsAuth";


const email: string = getEmailFromToken();

type UpcomingMatchesProps = {
  endpoint: string;
  title: string;
  isLadderTab: boolean;
};

const UpcomingMatches: React.FC<UpcomingMatchesProps> = ({ title, isLadderTab, endpoint }) => {
  const [upcomingMatches, setUpcomingMatches] = useState<UpcomingMatchData[]>([]);

  async function fetchUpcomingMatches() {
    try {
      const response = await getAuth('/matchmaking/finalmatchmakingresults/');
      const data = await response.json();
      setUpcomingMatches(Array.isArray(data) ? data : []);

    } catch (error) {
      console.error("Error fetching upcoming matches:", error);
      setUpcomingMatches([])
    }
  }

  useEffect(() => {
    fetchUpcomingMatches();
  }, []);

  const filteredMatches =
    isLadderTab == false ? upcomingMatches.filter((match) => {
      let exists = (match.team1Player1Email === email ||
        match.team1Player2Email === email ||
        match.team2Player1Email === email ||
        match.team2Player2Email === email
      );

      return exists;
    })
      : upcomingMatches;

  const matchDisplay = (
    <Center>
      <VStack>

        {/* Upcoming Matches directly below button */}
        <Box width="100%" mt={10}>
          <Center>
            <VStack>
              <HStack>
                <MdUpcoming size={"24"} />
                <Heading size="lg">{title}</Heading>
              </HStack>
              {filteredMatches.length === 0 ? (
                <Center>
                  <VStack mt={4}>
                    <Text fontSize="xl"> No upcoming matches :(</Text>
                    <Text fontSize="xl">Go join some!</Text>
                  </VStack>
                </Center>
              ) : (
                <VStack spacing={4}>
                  {filteredMatches.map(match => (
                    <UpcomingMatch key={
                      match.team1Player1Id +
                      match.team1Player2Id! +
                      match.team2Player1Id +
                      match.team2Player2Id! +
                      match.matchmakingRequestSessionId} match={match} />
                  ))}
                </VStack>
              )}
            </VStack>
          </Center>
        </Box>
      </VStack>
    </Center>
  );

  if (isLadderTab) {
    return (
      <Card variant={'elevated'} height={'100%'} overflow={'scroll'} minWidth={'lg'} width={'inherit'}>
        <CardHeader mt={'-2'}>
          {matchDisplay}
        </CardHeader>
      </Card>
    );
  } else {
    return matchDisplay;
  }


};

export default UpcomingMatches;




